import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { Link, Navigate } from "react-router-dom"

import { useSignIn } from "@/hooks/useSignIn"

export default function LogInPage() {
  const matches = useMediaQuery("(min-width:550px)")
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent={matches ? "center" : "flex-start"}
      height="100vh"
      sx={{ minHeight: 600 }}
      spacing={2}
    >
      <Stack
        alignItems="flex-start"
        sx={{ maxWidth: 400, width: "100%" }}
        spacing={6}
        p={6}
      >
        <Stack>
          <img src="/logo.svg" height={30} />
          <Typography variant="caption">Dashboard</Typography>
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <Typography variant="h3">Sign in to your API account</Typography>
        <LoginForm />
      </Stack>

      <Typography variant="body2">
        No account yet ? <Link to="/signup">Create an API account</Link>
      </Typography>
    </Stack>
  )
}

interface AdminLoginFormData {
  email: string
  password: string
}

export function LoginForm() {
  const { isLoading, mutate: signIn, isSuccess } = useSignIn()
  const { register, handleSubmit, errors } = useForm<AdminLoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  if (isSuccess) return <Navigate to="/" />

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(async (data) => {
        signIn(data)
      })}
      width="100%"
    >
      <Box mb={4}>
        <Typography variant="body2">Email</Typography>
        <TextField
          name="email"
          inputRef={register({
            required: "Required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid",
            },
          })}
          fullWidth
        />
      </Box>
      <div>
        <Typography variant="body2">Password</Typography>
        <TextField
          name="password"
          type={"password"}
          inputRef={register({ required: "Required" })}
          fullWidth
        />
      </div>

      <Button
        variant="contained"
        type="submit"
        disabled={isLoading}
        sx={{ mt: 10 }}
        size="large"
      >
        {isLoading ? "Logging in..." : "Go to Dashboard"}
      </Button>
    </Stack>
  )
}
