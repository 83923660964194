import React, { useState } from "react"
import {
  deleteWebhook,
  getWebhookList,
  Webhook,
  WebhookTriggerEvent,
} from "@/api/webhook"
import {
  Alert,
  Button,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { BookOpen, MoreHorizontal, PlusCircle } from "lucide-react"
import { useConfirm } from "material-ui-confirm"
import { enqueueSnackbar } from "notistack"

import { useDialog } from "@/hooks/useDialog"
import { LoadMoreButton } from "@/components/Buttons"
import ErrorAlert from "@/components/ErrorAlert"
import { Tutorial } from "@/components/Tutorial"
import WebhookForm from "@/components/WebhookForm"

export default function WebhookPage() {
  const { openDialog } = useDialog()
  return (
    <>
      <Stack direction="row" alignItems="flex-start" mb={10} spacing={2}>
        <Stack flex={1}>
          <Typography variant="h3">Webhooks</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Set up actions that are triggered when particular events happen.
          </Typography>
        </Stack>
        <Button
          startIcon={<BookOpen size={16} />}
          variant="outlined"
          component="a"
          href="https://developer.unipile.com/docs/webhooks-2"
          target="_blank"
        >
          Documentation
        </Button>
        <WebhookForm />
      </Stack>
      <Tutorial
        id="webhooks"
        title="🚀 Get started with webhooks"
        subtitle="Set up actions that are triggered when particular events happen."
        Buttons={
          <>
            <Button
              startIcon={<PlusCircle size={16} />}
              variant="outlined"
              onClick={() => openDialog("new")}
            >
              Setup a Webhook
            </Button>
            <Button
              startIcon={<BookOpen size={16} />}
              variant="outlined"
              component="a"
              href="https://developer.unipile.com/docs/webhooks-2"
              target="_blank"
            >
              Listen for events within your app
            </Button>
          </>
        }
        closable
      />
      <WebhooksList />
    </>
  )
}

function WebhooksList() {
  const {
    isLoading,
    error,
    data,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["webhooks"],
    queryFn: getWebhookList,
    getNextPageParam: (lastPage) => lastPage.cursor,
  })

  if (isLoading) return <SkeletonList />
  if (isError) return <ErrorAlert error={error} />

  if (data.pages[0] && data.pages[0].items.length === 0)
    return (
      <Typography variant="h4" textAlign="center" pt={10}>
        You don&apos;t have webhooks.
      </Typography>
    )

  return (
    <>
      <Paper>
        <Stack divider={<Divider />}>
          {data.pages.map((page) =>
            page.items.map((webhook) => (
              <WebhookItem key={webhook.id} webhook={webhook} />
            ))
          )}
        </Stack>
      </Paper>
      <LoadMoreButton
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        onClick={() => fetchNextPage()}
      />
    </>
  )
}

export const WebhookLabelMap: Record<WebhookTriggerEvent, string> = {
  message_received: "On new message",
  message_read: "On message read",
  message_reaction: "On message reaction",
  mail_sent: "On mail sent",
  mail_received: "On mail received",
  mail_opened: "On mail opened",
  mail_link_clicked: "On mail link clicked",
}

function WebhookItem({ webhook }: { webhook: Webhook }) {
  return (
    <Stack
      p={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flex={1}>
        <Typography variant="body2">{webhook.name}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {webhook.account_ids.length === 0
            ? "All accounts"
            : webhook.account_ids.length + " account(s)"}
        </Typography>
      </Stack>
      <Stack flex={1} alignItems="flex-start" gap={2}>
        {webhook.events ? (
          webhook.events.map((event) => (
            <Chip
              label={WebhookLabelMap[event]}
              color={
                webhook.source === "messaging"
                  ? "info"
                  : webhook.source === "email"
                  ? "success"
                  : webhook.source === "email_tracking"
                  ? "secondary"
                  : "warning"
              }
            ></Chip>
          ))
        ) : (
          <>
            {webhook.source === "messaging" && (
              <Chip label="New message" color="info"></Chip>
            )}
            {webhook.source === "email" && (
              <Chip label="Mail received" color="success"></Chip>
            )}
            {webhook.source === "account_status" && (
              <Chip label="On account status update" color="warning"></Chip>
            )}
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={1} flex={1}>
        <Chip label="POST"></Chip>
        <Typography variant="body2" color="text.secondary">
          {webhook.request_url}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={8}>
        <ButtonMenu webhook={webhook} />
      </Stack>
    </Stack>
  )
}

function ButtonMenu({ webhook }: { webhook: Webhook }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizontal size={21} />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <DeleteButton webhook={webhook} onDone={handleClose} />
      </Menu>
    </div>
  )
}

function DeleteButton({
  webhook,
  onDone,
}: {
  webhook: Webhook
  onDone: () => void
}) {
  const confirm = useConfirm()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: deleteWebhook,
    onSuccess: (data) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["webhooks"] })
        enqueueSnackbar("Webhook deleted", { variant: "default" })
      }, 300)
    },
    onError: () => {
      enqueueSnackbar("Unexpected error", {
        variant: "error",
        title: "Can't delete webhook",
      })
    },
  })
  const handleDelete = () => {
    onDone()
    confirm({
      title: "Delete webhook",
      content: (
        <Alert severity="warning" variant="outlined">
          Are you sure you want to delete this webhook ?
        </Alert>
      ),
      confirmationButtonProps: {
        color: "error",
      },
      confirmationText: "Delete webhook",
    }).then(() => {
      mutate(webhook.id)
    })
  }

  return (
    <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
      Delete webhook
    </MenuItem>
  )
}

function SkeletonList() {
  return (
    <Paper>
      <Stack divider={<Divider />}>
        <WebhookItemSkeleton />
        <WebhookItemSkeleton />
        <WebhookItemSkeleton />
      </Stack>
    </Paper>
  )
}

function WebhookItemSkeleton() {
  return (
    <Stack
      p={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack flex={1} spacing={1}>
        <Skeleton variant="rounded" width="50%" height={18} />
        <Skeleton variant="rounded" width="40%" height={17} />
      </Stack>
      <Stack flex={1}>
        <Skeleton width="30%" />
      </Stack>
      <Stack direction="row" spacing={1} flex={1}>
        <Skeleton width={50} />
        <Skeleton width="40%" />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={8}>
        <Skeleton width={70} />
        <Skeleton variant="rounded" width={35} height={35} />
      </Stack>
    </Stack>
  )
}
