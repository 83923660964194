import { PropsWithChildren, useState } from "react"
import { Box, Stack, Tooltip, TooltipProps, Typography } from "@mui/material"
import { CheckCircle2, Clipboard } from "lucide-react"

export function Copyable({
  value,
  children,
  placement = "top",
  title = <>Copy to clipboard</>,
  sx,
}: PropsWithChildren<
  { value: string; title?: JSX.Element } & Pick<
    TooltipProps,
    "placement" | "sx"
  >
>) {
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true)
    })
  }

  return (
    <Tooltip
      enterDelay={0}
      placement={placement}
      onClose={() => setTimeout(() => setCopied(false), 200)}
      title={
        copied ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <CheckCircle2 size={16} />
            <Typography variant="body2">Copied</Typography>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Clipboard size={16} />
            <Typography variant="body2">{title}</Typography>
          </Stack>
        )
      }
    >
      <Box
        sx={{
          cursor: "pointer",
          userSelect: "none",
          ...sx,
        }}
        onClick={handleClick}
      >
        {children}
      </Box>
    </Tooltip>
  )
}
